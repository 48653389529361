import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { SiteMap } from "./siteMap";

const Header = React.lazy(() =>
  import("../Components/Header/header.component")
);
const Home = React.lazy(() => import("../Pages/Home/home.page"));
const Projects = React.lazy(() => import("../Pages/Projects/projects"));
const Plans = React.lazy(() => import("../Pages/Plans/plans"));
const Footer = React.lazy(() => import("../Components/Footer/footer.component"))

const PageRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={<div className="suspense_style">Flashstorage Loading...</div>}
      >
        <Header />
        <Routes>
          <Route exact path={SiteMap.HomePage.path} element={<Home />} />
          <Route exact path={SiteMap.ProjectPage.path} element={<Projects />} />
          <Route exact path={SiteMap.PlansPage.path} element={<Plans />} />
        </Routes>
        <Footer/>
      </Suspense>
    </BrowserRouter>
  );
};

export default PageRoutes;
