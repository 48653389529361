export const SET_GRID_VIDEOS = "SET_GRID_VIDEOS"
export const SET_LANDING_VIDEO = "SET_LANDING_VIDEO"
export const SET_SERVICE_IMAGES = "SET_SERVICE_IMAGES"

export const setServicesImages = (payload) => (dispatch) => {
    dispatch({
        type: SET_SERVICE_IMAGES,
        payload: payload,
    });
};

export const setGridVideos = (payload) => (dispatch) => {
    dispatch({
        type: SET_GRID_VIDEOS,
        payload: payload,
    });
};
export const setLandingVideo = (payload) => (dispatch) => {
    dispatch({
        type: SET_LANDING_VIDEO,
        payload: payload,
    });
};
