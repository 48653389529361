import './App.css';
import PageRoutes from './Routes';
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import { setProjectImages } from './Redux/actions/projects.action';
import { importAll, loadImage, loadVideo } from './Helper/utils';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import logo from "./Assets/Images/logo.png"
import landingIntroVideo from "./Assets/Landing/For Flash Storage web.mp4"
import { setLandingVideo, setGridVideos, setServicesImages } from './Redux/actions/home.action';
import { setTeamImages } from './Redux/actions/team.action';

function App() {
  const [ismobile, setIsmobile] = useState(false)
  const [mounted, setMounted] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.screen.width <= 760) {
      setIsmobile(true)
    }
  }, [])

  const projectImages = importAll(
    require.context("./Assets/Gallery", false, /\.(png|jpe?g|svg)$/)
  );
  var gridVideos = {};
  if (!ismobile) {
    gridVideos = importAll(require.context("./Assets/Videos", false, /\.(mp4|mpeg4|webm)$/))
  }

  const teamImages = importAll(
    require.context("./Assets/Teams", false, /\.(png|jpe?g|svg)$/)
  );


  useEffect(() => {
    // window.scrollTo(0,0)

    (async () => {
      Promise.all([
        Object.keys(projectImages).map((key, value) => loadImage(projectImages[key])),
        (!ismobile) ? Object.keys(gridVideos).map((key, value) => loadVideo(gridVideos[key])) : {},
        Object.keys(teamImages).map((key, value) => loadImage(teamImages[key])),
        loadVideo(landingIntroVideo)
      ])
        .then(() => {
          // throw ("a");
          dispatch(setProjectImages(projectImages));
          if (!ismobile) {
            dispatch(setGridVideos(gridVideos));
          }
          dispatch(setLandingVideo(landingIntroVideo));
          dispatch(setTeamImages(teamImages));
          setMounted(true);
        })
        .catch(err => { console.log("Failed to load images", err); setError(true) })
    })();


    // menu(resultJson, category)
  }, [dispatch, projectImages])


  return (
    <div>{error ?
      <div className='app-main-container' >
        Some problem
      </div >
      :
      mounted ?
        <div className='app-main-container' >
          <PageRoutes />
        </div>
        :
        <div className='loading-container'>
          <div className='loading-logo'>
            <img src={logo} alt="" />
          </div>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '20px' }}>
            Please Wait...
            <CircularProgress />
          </Box>
        </div>}
    </div>

  );
}

export default App;
