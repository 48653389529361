import { SET_GRID_VIDEOS, SET_LANDING_VIDEO, SET_SERVICE_IMAGES } from "../../actions/home.action"

const initialState = {};

export const serviceImages = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVICE_IMAGES:
            return action.payload
        default:
            return state
    }
}

export const gridVideos = (state = initialState, action) => {
    switch (action.type) {
        case SET_GRID_VIDEOS:
            return action.payload
        default:
            return state
    }
}

export const landingVideo = (state = "", action) => {
    switch (action.type) {
        case SET_LANDING_VIDEO:
            return action.payload
        default:
            return state
    }
}