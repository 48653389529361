import { SET_TEAM_IMAGES } from "../../actions/team.action"

const initialState = {};

export const teamImages = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEAM_IMAGES:
            return action.payload
        default:
            return state
    }
}