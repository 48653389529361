export const SiteMap = {
    HomePage: {
      title: "Home",
      path: "/",
      description: "Flashstorage Home page",
    },
    ProjectPage: {
      title: "Project",
      path: "/projects",
      description: "Flashstorage Projects page",
    },
    PlansPage: {
      title: "Plans",
      path: "/Plans",
      description: "Flashstorage Plans page",
    }
  };