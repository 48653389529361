const loadImage = image => {
    return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
            setTimeout(() => {
                resolve(image)
            }, 2000)

        loadImg.onerror = err => reject(err)
    })
}
const loadVideo = video => {
    return new Promise((resolve, reject) => {
        var vElement = document.createElement('video');
        vElement.autoplay = true;
        vElement.preload = "auto";
        vElement.muted = true;
        vElement.loop = true;
        vElement.playsinline = true;
        vElement.playsInline = true;
        vElement.src = video;
        vElement.controls = true;
        // vElement.name = video.name;
        vElement.load();


        vElement.addEventListener('canplaythrough', function () {
            resolve(vElement);

        });

        vElement.addEventListener('error', function () {
            reject(vElement);
        });
    })
}

const importAll = require =>
    require.keys().reduce((acc, next) => {
        acc[next.replace("./", "")] = require(next);
        return acc;
    }, {});

export {
    loadImage,
    loadVideo,
    importAll
};
