import { SET_PLAN_INDEX } from "../../actions/plan.action"

const initialState = 0;

export const planIndex = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLAN_INDEX:
            return action.payload
        default:
            return state
    }
}