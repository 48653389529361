import { projectImages } from "./Projects/projects.reducer";
import { planIndex } from "./Plans/plans.reducer";
import { combineReducers } from "redux";
import { gridVideos, landingVideo, serviceImages } from "./Home/home.reducer";
import { teamImages } from "./Team/team.reducer";

const allReducers = combineReducers({
    projectImages,
    planIndex,
    gridVideos,
    landingVideo,
    serviceImages,
    teamImages
});

export default allReducers;